import React from "react";
import type { ThemeConfig } from "./buildtime/configSchema.js";
import type { Component } from "./types.js";

let _configs: ThemeConfig;
let _currentTheme: {
	name: string;
	description: string;
	version: string;
	theme: unknown;
	layouts: Record<string, Component>;
};
export const configure = (data: ThemeConfig) => {
	_configs = data;
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const resolveLayout = (component: any): Component => {
	if (!component) throw new Error("Cannot resoke layout for undefined.");
	const name = component.displayName || component.name;
	console.log(
		"[app-theme] Lookup layout for %s under %s theme: ",
		name,
		_currentTheme.name,
		_currentTheme.layouts[`${name}Layout`],
	);
	return (
		_currentTheme.layouts[`${name}Layout`] ||
		(({children}: any) => React.createElement("span", { className: `${name}Layout` }, ...children))
	);
};

export const load = async (name?: string) => {
	const themeName = name || _configs.default;
	const module = _configs.availableThemes.find(
		(t) => t.name === themeName,
	)?.module;
	if (!module) {
		console.warn("[app-theme] Unknown theme %s", themeName);
		return;
	}
	try{
		const m = await import(module)
		console.log("[app-theme] Loaded theme module %s", themeName, m);
		_currentTheme = m.Theme;

	} catch(e) {
		console.error("[app-theme] Could not load theme %s", themeName, e);
	}
};
