// biome-ignore lint/correctness/noUnusedImports: <explanation>
import * as React from 'react';
import type { JSX } from "react";
import { resolveLayout } from "./ThemeProvider.js";
import type { Component } from "./types.js";

type LayoutProps = JSX.ElementChildrenAttribute & {
  for: Component;
};

export const Layout = ({ children, ...props }: LayoutProps) => {
  const Lay = resolveLayout(props.for);
  //@ts-ignore
  return <Lay>{children}</Lay>;
};
