import type { Component } from "./types.js";

export * from "./Layout.js";

export const extractComponents = (children: Component[] | Component) => {
	let childrenAsArray: Component[];
	if (children && !Array.isArray(children)) {
		childrenAsArray = [children];
	} else {
		childrenAsArray = children;
	}
	const components = childrenAsArray.reduce(
		(acc, x) => {
			//@ts-ignore
			if (!x || !x.type) return acc;
			// file::componentName:lineNumber
			let name: string;
			//@ts-ignore
			if (x.type.displayName) {
				//@ts-ignore
				const nameParts = x.type.displayName.split(":");
				name = nameParts[nameParts.length - 2];
				//@ts-ignore
			} else if (x.type.name) {
				//@ts-ignore
				name = x.type.name;
			} else {
				return acc;
			}
			acc[name] = x;
			return acc;
		},
		{} as Record<string, Component>,
	);
	return components;
};
