import * as ThemeProvider from "./ThemeProvider.js";
import type { ThemeConfig } from "./buildtime/configSchema.js";

export * from "./index.js";

export const bootstrap = async(config: ThemeConfig) => {
	console.log("[app-theme] bootstrap", config);
	ThemeProvider.configure(config);
	await ThemeProvider.load();
};

export const mount = (element: HTMLElement) => {
	console.log("[app-theme] Mounting")
};
